// src/Privacy.tsx
import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const privacyPolicy = `
# Privacy Policy

This is our privacy policy.

## Information Collection

We collect the following types of information:
- Personal Information
- Usage Data

## Use of Information

We use the collected information to:
- Provide and maintain our service
- Improve user experience
- Communicate with you

## Security

We take the security of your information seriously.

## Contact Us

If you have any questions about this Privacy Policy, please contact us at support@example.com.
`;

const Privacy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <ReactMarkdown
          children={privacyPolicy}
          components={{
            h1: ({ node, ...props }) => <Typography variant="h3" component="h1" {...(props as any)} />,
            h2: ({ node, ...props }) => <Typography variant="h4" component="h2" {...(props as any)} />,
            h3: ({ node, ...props }) => <Typography variant="h5" component="h3" {...(props as any)} />,
            h4: ({ node, ...props }) => <Typography variant="h6" component="h4" {...(props as any)} />,
            p: ({ node, ...props }) => <Typography variant="body1" component="p" paragraph {...(props as any)} />,
            ul: ({ node, ...props }) => (
              <Box component="ul" sx={{ pl: 4, mb: 2 }} {...(props as any)}>
                {props.children}
              </Box>
            ),
            li: ({ node, ...props }) => <Typography component="li" {...(props as any)} />,
          }}
        />
      </Box>
    </Container>
  );
};

export default Privacy;
