// src/dataService.ts

export interface LessonItem {
    id: number;
    link: string;
    thumbnail: string;
    title: string;
    instructor: string;
    description: string;
  }
  
  // Mock data for lessons
  const mockLessons: LessonItem[] = [
    {
      id: 1,
      link: 'https://example.com/lesson1',
      thumbnail: 'imgs/seuss_sticker.png',
      title: 'Dr. Seuss',
      instructor: 'Instructor 1',
      description: 'Dr. Suess is a famous author. Learn more about his life and works. His art is also very interesting.',
    },
    {
      id: 4,
      link: 'https://example.com/lesson2',
      thumbnail: 'imgs/vangogh_sticker.png',
      title: "Van Gogh",
      instructor: 'Instructor 2',
      description: 'Did he really eat paint?  What\'s the deal with his ear?  Who was his brother?',
    },
    {
      id: 2,
      link: 'https://example.com/lesson2',
      thumbnail: 'imgs/banksy_1.png',
      title: "Banksy's Crimes",
      instructor: 'Instructor 2',
      description: 'Banky is a famous street artist. Learn more about his life and works. His art is also very interesting.',
    },
    {
      id: 3,
      link: 'https://example.com/lesson2',
      thumbnail: 'imgs/hensen_1.png',
      title: "Jim Henson",
      instructor: 'Instructor 2',
      description: 'Jim Henson is a famous puppeteer. Learn more about his life and works. His art is also very interesting.',
    },
    
  ];
  
  export const getLessons = async (): Promise<LessonItem[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockLessons);
      }, 500);
    });
  };
  