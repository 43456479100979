// src/components/Navbar.tsx
import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const buttonStyle = {
    fontSize: '2em', // Increase font size
    fontFamily: 'Montserrat', // Change font family
    marginLeft: '1em', // Add left margin
    '&:hover': {
      backgroundColor: 'transparent', 
      color: '#ee82ad',
    },
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: scrolled ? 'rgba(33,33,33,1)' : 'transparent',
          transition: 'background-color 0.3s ease',
          boxShadow: scrolled ? 1 : 0,
          color: 'inherit',
          zIndex: 1301,
          display: drawerOpen ? 'none' : 'block', // Hide AppBar when drawer is open
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0.8' }}>
              <img
                src="imgs/noodle_logo.png"
                alt="Noodle Academy"
                style={{ height: scrolled ? '50px' : '160px', padding: scrolled ? '0 0' : '16px 0', marginTop: '8px', transition: 'height 0.3s ease, padding 0.3s ease' }}
              />
            </Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block', color: "#fff" } }}>
            <Button color="inherit" component={Link} sx={buttonStyle} to="/">Home</Button>
            <Button color="inherit" component={Link} sx={buttonStyle} to="/lessons">Lessons</Button>
            <Button color="inherit" component={Link} sx={buttonStyle} to="/about">About</Button>
            <Button color="inherit" component={Link} sx={buttonStyle} to="/privacy">Privacy</Button>
          </Box>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: 'block', md: 'none', marginTop: '8px' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box sx={{ width: 250 }}>
          <List>
            <ListItem sx={{ justifyContent: 'flex-end' }}>
              <IconButton onClick={handleDrawerToggle}>
                <CloseIcon />
              </IconButton>
            </ListItem>
            <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/lessons" onClick={handleDrawerToggle}>
              <ListItemText primary="Lessons" />
            </ListItem>
            <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem button component={Link} to="/privacy" onClick={handleDrawerToggle}>
              <ListItemText primary="Privacy" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
