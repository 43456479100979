import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import LessonList from './components/LessonList';
import { getLessons, LessonItem } from './services/dataService';

const Home: React.FC = () => {
  const [lessons, setLessons] = useState<LessonItem[]>([]);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const fetchLessons = async () => {
      const data = await getLessons();
      setLessons(data);
    };

    fetchLessons();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'fixed',
          top: '-50vh', // Start the image higher than the viewport
          left: 0,
          width: '100%',
          height: '150vh', // Make the height larger to cover during scrolling
          backgroundImage: 'url(imgs/bg3.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: -1,
          transform: `translateY(${scrollY * 0.2}px)`, // Adjust the multiplier for a more subtle parallax effect
          transition: 'transform 0.1s',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(60, 0, 0, 0.7)', // Adjust the opacity value to your liking
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: 2,
          position: 'relative',
          minHeight: '100vh',
        }}
      >
        <Typography variant="h1" component="h1" gutterBottom sx={{ color: "#fff", fontSize: "7em", fontFamily: "Amatic SC", fontWeight: "700" }}>
          Noodle Academy is here to help you learn!
        </Typography>
        <Typography variant="h5" component="p" gutterBottom sx={{ color: "#fff", fontFamily: "Montserrat", fontSize: "3em" }}>
          Learn at your own pace with our AI teachers.
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          component={Link}
          to="/lessons"
          sx={{ mt: 3, fontSize: '2em'}}
        >
          Free Lessons
        </Button>
      </Box>
      <Container>
        <Box sx={{ my: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "80px" }}>
          <Box component="img" src="imgs/lsquig.png" alt="leaf" sx={{ width: '120px', height: 'auto', mr: 1 }} />
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: "Amatic SC", fontSize: "5em", textAlign: "center", color: "#fff", margin: "0px 40px" }}>
            Free Art Summer Camp
          </Typography>
          <Box component="img" src="imgs/rsquig.png" alt="reaf" sx={{ width: '120px', height: 'auto', ml: 1 }} />
        </Box>
        <Box display="flex" justifyContent="center" my={3} sx={{margin: "70px 0", maxWidth: "80%"}}>
            <img src="imgs/sep.png" alt="Decorative Flourish" style={{width: "100%"}}/>
        </Box>
        <Box sx={{ my: 4 }}>
          <LessonList lessons={lessons} />
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
