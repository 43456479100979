import React from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Link as MuiLink,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { LessonItem } from '../services/dataService';

interface LessonListProps {
  lessons: LessonItem[];
}

const LessonList: React.FC<LessonListProps> = ({ lessons }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" sx={{ padding: '20px' }}>
      {lessons.map((lessonItem, index) => (
        <React.Fragment key={lessonItem.id}>
          <Card
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : (index % 2 === 0 ? 'row' : 'row-reverse'),
              height: isSmallScreen ? 'auto' : '400px',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              borderRadius: '10px',
              overflow: 'hidden',
              marginBottom: '40px', // Add space between lessons
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: isSmallScreen ? '100%' : '60%',
                height: isSmallScreen ? '200px' : '100%',
                objectFit: 'contain',
              }}
              image={lessonItem.thumbnail}
              alt={lessonItem.title}
            />

            <CardContent
              sx={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '20px',
                width: isSmallScreen ? '100%' : '40%',
                backgroundColor: 'transparent',
              }}
            >
              <Box>
                <MuiLink href={lessonItem.link} target="_blank" rel="noreferrer" underline="none" color="inherit">
                  <Typography component="h1" variant="h3" sx={{ fontSize: '4em', color: "#fff", fontWeight: 'bold', marginBottom: '10px', fontFamily: 'Amatic SC' }}>
                    {lessonItem.title}
                  </Typography>
                </MuiLink>
                <Typography variant="body1" color="inherit" sx={{ fontSize: '1.2em', fontFamily: 'Montserrat' }}>
                  {lessonItem.description}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  color="inherit"
                  component={MuiLink}
                  href={lessonItem.link}
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    borderColor: 'white',
                    color: 'white',
                    fontSize: '1.5em',
                    padding: '10px 20px',
                    borderRadius: '30px',
                    fontFamily: 'Montserrat',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderColor: 'white',
                    },
                  }}
                >
                  Start Lesson
                </Button>
              </Box>
            </CardContent>
          </Card>
          {index < lessons.length - 1 && (
            <Box display="flex" justifyContent="center" my={3} sx={{margin: "70px 0"}}>
                <img src="imgs/sep.png" alt="Decorative Flourish" />
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default LessonList;
