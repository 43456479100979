// About.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const About: React.FC = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          This page contains information about our company and team. We are dedicated to providing the best services to our clients and continuously improving our offerings.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Our mission is to deliver high-quality products that meet the needs and expectations of our customers.
        </Typography>
      </Box>
    </Container>
  );
}

export default About;
