// src/Lessons.tsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import LessonList from './components/LessonList';
import { LessonItem, getLessons } from './services/dataService';

const Lessons: React.FC = () => {
  const [classes, setLessons] = useState<LessonItem[]>([]);

  useEffect(() => {
    const fetchLessons = async () => {
      const data = await getLessons();
      setLessons(data);
    };

    fetchLessons();
  }, []);

  return (
    <Box
      sx={{
        background: `linear-gradient(135deg, #f0f2f5 25%, transparent 25%) -50px 0,
                     linear-gradient(225deg, #f0f2f5 25%, transparent 25%) -50px 0,
                     linear-gradient(315deg, #f0f2f5 25%, transparent 25%),
                     linear-gradient(45deg, #f0f2f5 25%, transparent 25%)`,
        backgroundSize: '100px 100px',
        backgroundColor: '#ffffff',
        minHeight: '100vh',
        padding: '20px',
      }}
    >
      <Container>
        <Box sx={{ marginTop: '120px' }}></Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Available Lessons
          </Typography>
          <LessonList lessons={classes} />
        </Box>
      </Container>
    </Box>
  );
}

export default Lessons;
